@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    background-color: #202937; /* Your dark background */
    color: white;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif; /* Ensure consistency */
  }
  
  /* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1f2937; /* Dark background (matches UI) */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #b2894d; /* Gold primary color */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #d1a564; /* Lighter gold on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #b2894d #1f2937;
}
